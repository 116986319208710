import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import StoryblokLink from '@/components/StoryblokLink/StoryblokLink';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { ArrowForwardIcon } from '@/icons/ArrowForwardIcon';

export interface WebsiteSubjectBlokProps extends SbBlokData {
  link: Link;
  label: string;
  icon: Asset;
}

export interface WebsiteSubjectProps {
  blok: WebsiteSubjectBlokProps;
  theme?: 'default' | 'ideal' | '';
}

const WebsiteSubject = ({ blok, theme = 'default' }: WebsiteSubjectProps) => {
  const { link, label, icon } = blok;

  if (theme === 'ideal') {
    return (
      <StoryblokLink
        link={link}
        className="px-6 py-8 lg:py-7 sm:px-6 xl:p-7 border-2 border-GSsteel/300 rounded hover:bg-GSblue/50 hover:border-GSblue/300 w-full bg-GSbase/white active:border-GSblue/300 active:bg-GSblue/50"
        {...storyblokEditable(blok)}
      >
        <div className="break-words flex items-center gap-4">
          <StoryblokImage blok={icon} className="h-6 lg:h-10" />
          <div className="w-full font-worksans text-GSdeep/500 font-semibold lg:font-bold text-xl truncate">
            {label}
          </div>
          <div className="lg:hidden">
            <ArrowForwardIcon />
          </div>
        </div>
      </StoryblokLink>
    );
  }

  return (
    <StoryblokLink
      link={link}
      className="p-4 sm:p-6 xl:p-8 border border-GSblue/300 shadow-lg rounded hover:bg-GSblue/100 w-full"
      {...storyblokEditable(blok)}
    >
      <div className="break-words flex flex-col items-center gap-2 xl:gap-4">
        <StoryblokImage blok={icon} className="h-12 sm:h-14 lg:h-16" />
        <div className="w-full text-center font-worksans text-GSdeep/500 font-medium text-sm sm:text-base xl:text-lg">
          {label}
        </div>
      </div>
    </StoryblokLink>
  );
};

export default WebsiteSubject;
