export const ArrowForwardIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_698_10763"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.174561" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_698_10763)">
      <path
        d="M14.831 12.1746L7.29826 4.64181C7.09959 4.44314 7.00284 4.20664 7.00801 3.93231C7.01318 3.65798 7.11509 3.42139 7.31376 3.22256C7.51243 3.02389 7.74893 2.92456 8.02326 2.92456C8.29759 2.92456 8.53418 3.02389 8.73301 3.22256L16.3888 10.8938C16.5694 11.0746 16.7033 11.2772 16.7905 11.5016C16.8778 11.7259 16.9215 11.9502 16.9215 12.1746C16.9215 12.3989 16.8778 12.6232 16.7905 12.8476C16.7033 13.0719 16.5694 13.2745 16.3888 13.4553L8.71751 21.1266C8.51884 21.3252 8.28493 21.422 8.01576 21.4168C7.74643 21.4116 7.51243 21.3097 7.31376 21.1111C7.11509 20.9124 7.01576 20.6759 7.01576 20.4016C7.01576 20.1272 7.11509 19.8906 7.31376 19.6918L14.831 12.1746Z"
        fill="black"
      />
    </g>
  </svg>
);
